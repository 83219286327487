import { AccountDocument } from '../types/account';
import { exhaustiveCheck } from './utils';

export function getAvailableForBudget(
  accounts: Pick<
    AccountDocument,
    'include_in_available' | 'type' | 'current' | 'available'
  >[]
) {
  if (!accounts) return 0;
  let total = 0;
  accounts.map(account => {
    if (!account.include_in_available) return;
    if (account.type === 'credit' || account.type === 'loan') {
      total += account.current;
    } else {
      total += account.available;
    }
  });
  return Number(total.toFixed(2));
}

export function canIncludeInSpendingPlan(a: Pick<AccountDocument, 'type'>) {
  return a.type === 'credit' || a.type === 'depository';
}

export type AccountState = 'budget' | 'tracked' | 'excluded';

export function getAccountState(
  account: Pick<AccountDocument, 'deleted' | 'include_in_available'>
): AccountState {
  if (account.deleted) {
    return 'excluded';
  } else if (account.include_in_available) {
    return 'budget';
  } else if (!account.include_in_available) {
    return 'tracked';
  } else {
    return 'excluded';
  }
}

export function getAccountUpdateFromState(
  newState: AccountState
): Pick<AccountDocument, 'deleted' | 'include_in_available'> {
  switch (newState) {
    case 'budget': {
      return {
        include_in_available: true,
        deleted: false,
      };
    }
    case 'tracked': {
      return {
        include_in_available: false,
        deleted: false,
      };
    }
    case 'excluded': {
      return {
        include_in_available: false,
        deleted: true,
      };
    }
    default: {
      return exhaustiveCheck(newState);
    }
  }
}

export function getAccountDisplayNumber(
  account: Pick<AccountDocument, 'type' | 'current' | 'available'> | undefined
) {
  if (!account) return 0;
  return account.type === 'credit' || account.type === 'loan'
    ? account.current
    : account.available;
}
